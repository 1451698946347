import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import './index.css';
import 'bootstrap-4-grid/css/grid.min.css';

import Navigation from './components/Navigation';
import Header from './components/Header';
import Footer from './components/Footer';

const root = document.getElementById('root');

const images = [...Array(7)].map((_, i) => require(`./assets/img/${i + 1}.jpg`));

const App = () => {
  return (
    <div>
      <Navigation />
      <Header image={images[23]} images={images} />
      <Footer />
    </div>
  )
}

ReactDOM.render(<App />, root);

serviceWorker.unregister();
