import React, {useState} from 'react';
import ReactMarkdown from 'react-markdown';
import './Footer.css';

import opening from '../data/hours.md';

import whatsapp from '../assets/icons/whatsapp.svg';
import star from '../assets/icons/star.svg';
import maps from '../assets/icons/location.svg';
import facebook from '../assets/icons/facebook.svg';
import instagram from '../assets/icons/instagram.svg';
import tripAdvisor from '../assets/icons/tripAdvisor.svg';

import michelinStars from '../assets/icons/michelin-stars.png';

const Footer = () => {
  const [hours, setHours] = useState('');

  const currentYear = new Date().getFullYear();

  fetch(opening).then(res => res.text()).then(md => {
    setHours(md);
  })

  return (
    <div className="footer">
      <div className="triangle"></div>
      <div className="purple">
        <div className="container">
          <div className="row">
            <div className="col-md line">
              <b>MAN ESSE GmbH</b><br/>
              Hopfenstrasse 2<br/>
              8045 Zürich-Wiedikon<br/>
              T +41 44 462 01 01<br/>
              <a href="mailto:info@maisonmanesse.ch">info@maisonmanesse.ch</a>
            </div>
            <div className="col-md line">
              <p>
                <b>OPENING HOURS</b>
              </p>
              <ReactMarkdown source={hours}/>
            </div>
            <div className="col-md line icons">
              <a target="_blank" rel="noopener noreferrer" href="https://wa.me/41794730101"><img src={whatsapp} className="icon" alt="Star"/></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.viamichelin.com/web/Restaurant/Zurich-8045-Maison_Manesse-9iuqus1z"><img src={star} className="icon" alt="Star"/></a>
              <a target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/3ahX9"><img src={maps} className="icon" alt="Google Maps"/></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/maisonmanesse/"><img src={instagram} className="icon" alt="Instagram"/></a>
              <a target="_blank" rel="noopener noreferrer" href="http://www.facebook.com/maisonmanesse"><img src={facebook} className="icon" alt="Facebook"/></a>
              <a target="_blank" rel="noopener noreferrer" href="https://www.tripadvisor.com/Restaurant_Review-g188113-d4367030-Reviews-Maison_Manesse-Zurich.html?m=19905"><img src={tripAdvisor} className="icon" alt="Trip Advisor"/></a>

              <div className="michelin">
                <img className="michelin-stars" src={michelinStars}/>
              </div>
              <div className="links">
                <a href="/s3/Term_Conditions.pdf">Terms & Conditions</a>
              </div>
              <div className="copyright">© {currentYear} Maison Manesse | studiobuergisser.com</div>
            </div>
          </div>
        </div>
      </div>
      <div className="underline"></div>
    </div>
  );
}

export default Footer;
