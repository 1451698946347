import React from 'react';
import {useState, useEffect} from 'react';
import { useTransition, animated, config } from 'react-spring'

import './Header.css';

import logo from '../assets/icons/maisonmanesse_logo.svg';


const Header = ({image, images}) => {

  const slides = images.map((url, index) => ({id: index, url}));
  const child = <img src={logo} className="logo bounceIn" alt="MAISON MANESSE. Hopfenstrasse 2, Zürich Manesseplatz" />
  slides[0] = {...slides[0], child }

  const [index, set] = useState(0)
  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses
  })

  const handleClick = () => {
    set(state => (state + 1) % slides.length);
  }

  useEffect(() => void setInterval(() => set(state => (state + 1) % slides.length), 4000), [])


  return (
    <div className="slider">
    {
      transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className="slide"
          style={{ ...props, backgroundImage: `url(${item.url})` }}
          onClick={() => handleClick()}>
          {child}
        </animated.div>
      ))
    }
    </div>
  );
}

export default Header;
