import React, {useState} from 'react';
import './Navigation.css';

const Navigation = () => {
  return (
    <div>
      <ul className="call-to-action">
        <li><a href="https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiU2pkcjhmazdueWIyYXQ5WXIiLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9">Reservation</a></li>
        <li className="dropdown"><a href="">Our Menu</a>
            <ul className="dropdown-content">
              <li><a href="/s3/dinner.pdf">Dinner</a></li>
              <li><a href="/s3/Znacht.pdf">Znacht</a></li>
              <li><a href="/s3/wines.pdf">Wines</a></li>
            </ul>
        </li>
      </ul>
    </div>
  )
}

export default Navigation;
